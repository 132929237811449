import React from 'react';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

function Copyright() {
  return (
    <div>
        <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
            <Typography
                variant="subtitle2"
                align="center"
                color="text.secondary"
                component="p"
            >
                BulbGo is the ultimate bulb catalog app for lighting enthusiasts. Explore a vast collection of bulbs from various brands and styles, all in one convenient place. Whether you're searching for energy-efficient LED bulbs, vintage filament bulbs, or specialty smart bulbs, BulbGo has you covered. Discover detailed product information, compare features, and find the perfect bulbs to brighten up your home or workspace. With BulbGo, your search for the ideal bulbs is simplified and illuminating.
            </Typography>
        </Box>
        <Typography variant="body2" align="center" style={{paddingBottom: '20px'}}>
            {'Copyright © '}
            <Link color="inherit" href="https://bulbgo.com/">
            bulbgo.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    </div>
  );
}

export default Copyright;